import { Link } from 'gatsby';
import React from 'react';

export default function PricingComp({ priceTable }) {
  return (
    <section id="pricing" className="pricing mt-5">
      <div className="container mt-5">
        {priceTable.map((price, index) => (
          <div className="row price-row" key={index}>
            {price.title_d_none ? (
              <div className="section-header">
                <h2>{price.title}</h2>
                <div className="bar"></div>
              </div>
            ) : (
              ""
            )}

            {price.body.map((priceValue, index) => (
              <div className={priceValue.col} key={index}>
                <div className={`pricingTable ${priceValue.color}`}>
                  <span
                    className={`badge-show ${
                      priceValue.badge ? "badge bg-primary " : "d-none"
                    }`}
                  >
                    Most Popular
                  </span>

                  <div className="pricingTable-header">
                    <i className="fa fa-adjust"></i>
                    <div className="price-value">
                      <span>₹{priceValue.ofrprice}</span>
                      <span className="month">₹{priceValue.price}</span>
                    </div>
                  </div>
                  <div className="p-content">
                    <h3 className="heading">{priceValue.header}</h3>
                    {priceValue.content.map((points, index) => (
                      <div className="pricing-content" key={index}>
                        <ul>
                          <div className="row">
                            <div className="col-md-2">
                              <i className="bi bi-check-circle-fill"></i>
                            </div>
                            <div className="col-md-10">
                              <li>
                                <span className="pricing-text">
                                  {points.point}
                                </span>
                              </li>
                            </div>
                          </div>
                          {/* <li><b></b><span className='pricing-text'> {points.point}</span></li> */}
                        </ul>
                      </div>
                    ))}
                  </div>
                  {priceValue.card_link ? (
                    <div className="pricingTable-signup">
                      <Link to={priceValue.card_links}>Enquiry Now</Link>
                    </div>
                  ) : (
                    <div className="pricingTable-signup">
                      <Link
                        to="/pricing-submit"
                        state={{
                          final: priceValue.ofrprice,
                          price: priceValue.price,
                        }}
                      >
                        sign up
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
