import React from "react";
import "../styles/itr-filing.css";
import Layout from "../components/Layout";
import GeneralTab from "../components/HTabs";
import { Seo } from "../components/seo";
import Counter from "../components/Counter";
import FAQAccord from "../components/GFlush";
import Hero from "../components/Hero";
import About from "../components/About";
import ImgContent from "../components/GSTTemp/ImgContent";
import Document from "../components/Document";
import Service from "../components/Services";
import Cta from "../components/Cta";
import PricingComp from '../components/PricingComp';

export default function Itr_Filing() {
  /* Sliders */
  const itrSlider = [
    {
      header_color: false,
      content_color: false,
      marginTop: 30,
      background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
      header: `Income Tax Return Filings`,
      content: `Apply for ITR Filings with TODAYFILINGS Experts`,
      image: '/imgs/business/llp.png',
      alt_tag: 'Best Online ITR E-filings Service in Hosur',
    },
    // {
    //   header_color: false,
    //   content_color: false,
    //   marginTop: 10,
    //   background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
    //   header: `Simple ITR Filings Steps`,
    //   content: `Register Online ITR E-filing simple and few steps`,
    //   image: '/imgs/business/sliders/pvt-slide.png',
    //   alt_tag: 'Best ITR Filing near Bangalore',
    // },

    // {
    //   header_color: false,
    //   content_color: false,
    //   marginTop: 40,
    //   background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
    //   header: `ITR E-filings in Hosur`,
    //   content: `One the top most success rated ITR filings service in Hosur`,
    //   image: '/imgs/business/sliders/pvt.png',
    //   alt_tag: 'TODAYFILINGS ITR E-filings Service',
    // },
  ];

  /* Pricing Data */
  // const PriceCompData = {
  //   header: `GST Registration`,
  //   buyBtnLink: `#pricing`,
  //   ori_price: `6999`,
  //   final_price: `2999`,
  //   content: [
  //     {
  //       points: `PAN `,
  //       icon: "/imgs/icons/gst.png",
  //       alt_tag: "TODAYFILINGS GST filing service",
  //       background: "",
  //     },
  //     {
  //       points: `DSC `,
  //       icon: "/imgs/icons/itr_filing.png",
  //       alt_tag: "TODAYFILINGS ITR E-filing service",
  //       background: "bg-f78acb",

  //     },
  //     {
  //       points: `Domain`,
  //       icon: "/imgs/icons/enterprises.png",
  //       alt_tag: "TODAYFILINGS GST filing service",
  //       background: "bg-eb6b3d",

  //     },
  //     {
  //       points: `GST `,
  //       icon: "/imgs/icons/consultant.png",
  //       alt_tag: "TODAYFILINGS - Best Enterprises Products service in Hosur",
  //       background: "bg-c679e3",

  //     },
  //   ],
  // }
  /* About Data Without Form*/
  const itrAboutData = {
    // price: PriceCompData,
    title: `ITR Filing`,
    sub_title: `For professionals who are salaried and self-employed.`,
    paragraph: `Only with ease of e-filing, which is done entirely online,
    filing your income tax returns is now simpler than ever. In
    addition to being secure, submitting taxes online is simpler
    and faster than going in person to the income tax office.As a 
    responsible Indian citizen, you are required to file your
    income tax returns (ITR), and you can do this online from the
    convenience of your home.`,
    // paragraph: [`Only with ease of e-filing, which is done entirely online,
    // filing your income tax returns is now simpler than ever. In
    // addition to being secure, submitting taxes online is simpler
    // and faster than going in person to the income tax office.`,

    //   `As a responsible Indian citizen, you are required to file your
    // income tax returns (ITR), and you can do this online from the
    // convenience of your home.`,

    //   `To make tax compliance easier, the Income Tax Department has divided
    // taxpayers into groups according to their sources of income and level of income.
    //  In India, individuals with incomes up to Rs. 50 lakh are required to file ITR
    //  Returns.`],
    header: `Tax E-Filing Techniques`,
    points: [`Assisted Tax Filing`, `Self E-Filing `, `Live Video Tax Filing`],
  };
  /* Service Data */
  const ItrServiceData = {
    header: `Documents Required in Self E-filling `,
    content: '',
    image: '/imgs/business/subsidiary.png',
    alt_img: 'TODAYFILINGS Self E-Filing Registration',
    points: [
      `Pension income`,
      `Interest income`,
      `Foreign Income`,
      `Business income`,
      `Self Employed`,
      `Switched jobs`,
    ],
  };
  /* Img Content Component Data */
  const ItrIcData = {
    id: 'dsc',
    header: 'LIVE Video Filing',
    image: '/imgs/assited/live.png',
    alt_tag: 'Digital signature certificates ',
    points: [
      {
        head: '',
        content: `TODAYFILINGS has made Online Filing super easy! Take the first step
        towards filing your income tax return. Select all the sources
        of your income (to accurately e-file) and click on Continue to
        experience TODAYFILINGS customized & the easiest e-filing process.`,
        icon: false,
      },
      {
        head: '',
        content: ` An exclusive service that helps you file Income Tax Live with
        highly experienced & top-rated tax experts in just 60 mins.`,
        icon: false,
      },
      {
        head: 'Upload all your documents',
        content: `Schedule a Live meeting with our expert CA.`,
        icon: true,
      },
      {
        head: '',
        content: `Get ITR-Verification after e-filing renewing computation`,
        icon: true,
      },
    ],
  };

  /* FaQ Data */
  var ItrFilingFAQ = [
    {
      header: 'Who should i file ITR?',
      body: [
        {
          content: `ITR 1 Forms are submitted by taxpayers and residents of India 
          who have a combined income of up to INR 50 lakhs from salary, one primary 
          residence, other sources (interest, dividends, etc.), and up to INR 5,000 
          from agriculture.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'ITR online filing is it possible?',
      body: [
        {
          content: `One can access the pre-filling and filing of ITR services if they 
          are registered users of the e-Filing portal. Individual taxpayers can make 
          use of this facility to submit ITR-1s online using the e-Filing website.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What if I fail to submit an ITR?',
      body: [
        {
          content: `If a taxpayer misses the deadline for filing an ITR, penalty interest
           at the rate of 1% per month is applied to the unpaid tax. Furthermore, section 
           234A takes effect starting on the original due date, which in this case is 
           July 31, 2021, if the unpaid tax balance reaches Rs 1 lakh.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'Do I have to declare mutual fund dividends on my tax return?',
      body: [
        {
          content: `It is required to record dividend income from mutual funds. Dividend 
          income is not taxed under Section 10 of the Internal Revenue Code (35). You must 
          include it in Part D of your ITR, under the heading "exempt Income" (others).`,
          icon: false,
        },
      ],
    },
    {
      header:
        'While filing ITR  is it necessary to show the interest income from other sources if the TDS is already deducted?',
      body: [
        {
          content: `Yes, it is necessary to include interest income from other sources even if the tax is deducted by the bank.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to file ITR returns offline?',
      body: [
        {
          content: `ITR returns can be filed offline: By furnishing a return in Physical Paper form after which an 
          acknowledgment is issued at the time of submission of the physical paper return.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who should not file ITR Form?',
      body: [
        {
          content: `ITR cannot be used by a tax assessee who is not claiming exemption under Section 139 (4A),
           Section 139 (4B), Section 139 (4C), or Section 139 (4D).`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the due date for filing ITR Form?',
      body: [
        {
          content: `The due date for all Tax Access, whose accounts are required to be audited, is 30th September.
           The due date for Tax Access, whose accounts are not required to be audited, is 31st July.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to download Income tax returns form?',
      body: [
        {
          content: `Download the Income Tax Return Forms through the following steps: Visit the official website of 
          income tax Income Tax India Filing Click on the tab of Downloads on the homepage. A list of forms will be 
          available thereafter. Select the form in an excel sheet or java utility. Continue to fill the downloaded 
          form for filing the return`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What are the requirements to be fulfilled by the access after filing ITR ?',
      body: [
        {
          content: `Once the return is filed, the assessee shall get the print of two copies of the ITR- form, 
          one copy shall be sent through the post to the CPC office and the second copy shall be retained for record.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the consequences of false statements in return?',
      body: [
        {
          content: `Any person making false statements in return or 
          schedules is liable for prosecution u/s 277 of Income Tax Act 1961 with imprisonment and fine.`,
          icon: false,
        },
      ],
    },
  ];
  /* Tabs Data */
  const ItrFilingTabsData = {
    id: 'features',
    heading: 'Assisted Filing',
    paragraph: '',
    body: [
      {
        tab_title: 'Capital Gains',
        tab_content: [
          {
            content_title: 'Capital Gains',
            content_paragraph: ` Capital gain can be defined as any profit that is received
          through the sale of a capital asset. The profit that is
          received falls under the income category. Therefore, a tax
          needs to be paid on the income that is received. The tax
          that is paid is called capital gains tax and it can either
          be long term or short term. The tax that is levied on long
          term and short term gains starts from 10% and 15%,
          respectively.`,
            points: [
              `Under the Income Tax Act, capital gains tax in India need
            not be paid in case the individual inherits the property
            and there is no sale.`,
              `Upload your documents.`,
              `Review Computation sheet.`,
              `Get ITR-Verification after e-filing`,
            ],
          },
        ],
        content_img: '/imgs/assited/cap.png',
        alt_tag: 'TODAYFILINGS-ITR Capital Gains',
      },
      {
        tab_title: 'Salaried Income',
        tab_content: [
          {
            content_title: 'Salaried Income',
            content_paragraph: `Salary income is the money an employee receives from a
          current or former employer in exchange for doing tasks
          related to their employment.`,
            points: [
              `Get a professional to handle your taxes if you merely make
            a salary and receive interest from fixed deposits or
            savings accounts. No fuss. entirely digital.`,
              `Upload your documents.`,
              `Review computation sheet.`,
              `Get ITR-Verification after e-filing.`,
            ],
          },
        ],
        content_img: '/imgs/assited/salary.png',
        alt_tag: 'TODAYFILINGS-ITR For Salaried Income',
      },
      {
        tab_title: 'Advance Tax',
        tab_content: [
          {
            content_title: 'Advance Tax',
            content_paragraph: ` Advance tax is tax payable by individuals who have sources
          of income other than their salary. This is applicable to
          rent, capital gains from shares, fixed deposits, lottery
          winnings, etc. It can be paid online or through certain
          banks.`,
            points: [
              `Purchase Plan.`,
              `Give us specifics about your taxable income and any deductions
            that are available.`,
              `The amount of tax that must be deposited as advance tax before the
            deadline will be provided to you by TODAYFILINGS experts.`,
              `Due to the difficulties in accurately calculating dividend
            revenue, they used to have to pay interest on advance
            taxes on dividend income. TODAYFILINGS will assist them in avoiding
            this.`,
            ],
          },
        ],
        content_img: '/imgs/assited/advance.png',
        alt_tag: 'TODAYFILINGS-ITR For Advance Tax',
      },
      {
        tab_title: 'Foreign Income',
        tab_content: [
          {
            content_title: 'Foreign Income',
            content_paragraph: `Foreign earned income serves as the basis for the foreign
          earned income exclusion, foreign housing exclusion, and
          foreign housing deduction. For these purposes, foreign
          earned income is money you receive for work you do abroad
          during a time when your tax home is abroad and you pass
          the physical presence test or the bona fide residence
          test.`,
            points: [
              `Upload your documents.`,
              `Review computation sheet.`,
              `GReturn filed & acknowledgement generated.`,
              `For individual NRIs and Indians with income in India who
            are residing overseas, this plan is ideal. Obtain
            professional assistance while submitting your income
            taxes.`,
            ],
          },
        ],
        content_img: '/imgs/assited/foreign.png',
        alt_tag: 'TODAYFILINGS is the Best ITR Service in Hosur',
      },
    ],
  };

  /* Document Section Data */
  const ItrDocData = {
    id: 'itr-doc',
    heading: `Self E-Filing`,
    sub_heading: `File your ITR hassle-free entirely by yourself in under 3
      minutes. We’ve made e-filing incredibly easy for you.`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/it/form.png`,
        alt_tag: `form 16`,
        header: `Don't have Form 16?`,
        description: `We will pre-fill your data from IT Department and help you
          with filing returns undefined.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/it/stock.png`,
        alt_tag: `Stocks`,
        header: `Invested in Stocks`,
        description: `Simply Upload your P&L Report and we will auto-fill all your
          data and help you with filing returns undefined.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/it/mutual.png`,
        alt_tag: `Mutual Funds`,
        header: `Mutual Funds`,
        description: `Simply Upload your P&L Report and we will auto-fill all your
          data and help you with filing returns undefined.`,
      },
    ],
  };

  /* Pricing Details  */
  const pricingDetails = [
    {
      title: `ITR Filing for individual`,
      body: [
        {
          col: 'col-md-3 col-sm-6',
          header: `Salary/House rent income`,
          ofrprice: `999`,
          price: `4199`,
          color: '',
          content: [
            {
              point: `Less than 50 lacs in salary`,
              icon: false,
            },
            {
              point: `Household assets`,
              icon: false,
            },
            {
              point: `Withdrawals from bank deposits & PF funds`,
              icon: false,
            },
            {
              point: `Donations`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          badge: true,
          header: `Salary/House rent income`,
          ofrprice: `1999`,
          price: `4999`,
          color: 'green',
          content: [
            {
              point: `Less than 50 lacs in salary`,
              icon: false,
            },
            {
              point: `Household assets`,
              icon: false,
            },
            {
              point: `Withdrawals from bank deposits & PF funds`,
              icon: false,
            },
            {
              point: `Donations`,
              icon: false,
            },
            {
              point: `Dividends greater then 10.`,
              icon: false,
            },
            {
              point: `Director of a company`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          header: `Capital gains income`,
          ofrprice: `3999`,
          price: `7199`,
          color: 'blue',
          content: [
            {
              point: `Gains from equities, mutual funds, and real estate`,
              icon: false,
            },
            {
              point: `Earnings from prizes and the lottery`,
              icon: false,
            },
            {
              point: `Includes all expenses in the salary/home income plan from prizes and the lottery`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          header: `Earnings from foreign`,
          ofrprice: `5999`,
          price: `8499`,
          color: 'red',
          content: [
            {
              point: `Earnings made outside of India`,
              icon: false,
            },
            {
              point: `Profits for NRE and NRO accounts made in India`,
              icon: false,
            },
            {
              point: `Selling ESOPs or RSUs`,
              icon: false,
            },
            {
              point: `DTAA instruction`,
              icon: false,
            },
            {
              point: `Everything in the capital gains income plan is covered`,
              icon: false,
            },
            {
              point: `Includes filing returns for shares, SOPs, and foreign investments in India`,
              icon: false,
            },
            {
              point: `Foreign asset disclosure for Indian residents`,
              icon: false,
            },
          ],
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='ITR Filing'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          <Hero sliders={itrSlider} />

          <About about_data={itrAboutData} />

          <Counter />

          <Service item={ItrServiceData} />

          <Document docSecData={ItrDocData} />

          <GeneralTab HTabsData={ItrFilingTabsData} />

          <ImgContent item={ItrIcData} />
          <PricingComp priceTable={pricingDetails} />
          <Cta />
          <FAQAccord items={ItrFilingFAQ} />
        </main>
      </Layout>
    </div>
  );
}
