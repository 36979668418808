import React from "react";
import { Link } from "gatsby";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { TypeAnimation } from 'react-type-animation';

const Hero = ({ sliders }) => {
  return (
    // <>
    //   {sliders.map((slider, index) => (
    //     <section
    //       className='hero'
    //       key={index}
    //       style={{ background: slider.background }}
    //     >
    //       <div className='container'>
    //         <div className='row'>
    //           <div className='col-md-8'>
    //             <div className='content aos-init aos-animate'>
    //               <h1
    //                 className={`${slider.header_color ? 'text-white' : ''}`}
    //                 data-aos='fade-down'
    //                 data-aos-offset='500'
    //               >
    //                 {slider.header}
    //               </h1>
    //               <p
    //                 className={`${slider.content_color ? 'text-white' : ''}`}
    //                 data-aos='fade-up'
    //                 data-aos-offset='00'
    //               >
    //                 {slider.content}
    //               </p>

    //               <Link
    //                 className='btn btn-md btn-success demo-btn'
    //                 to='/contact'
    //                 data-aos='fade-left'
    //                 data-aos-offset='300'
    //               >
    //                 <div
    //                   className='icons'
    //                   data-aos='fade-right'
    //                   data-aos-offset='300'
    //                 >
    //                   Contact us <i className='bi bi-indent m-right'></i>
    //                 </div>
    //               </Link>
    //             </div>
    //           </div>
    //           <div className='col-md-4'>
    //             <div
    //               className='slide-img'
    //               style={{
    //                 marginTop: slider.marginTop,
    //                 marginBottom: slider.marginBottom,
    //               }}
    //             >
    //               <img
    //                 src={slider.image}
    //                 className='img-fluid aos-init aos-animate'
    //                 alt={slider.alt_tag}
    //                 data-aos='fade-up'
    //                 data-aos-offset='200'
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   ))}
    // </>
    <AwesomeSlider
      bullets={true}
      mobileTouch={true}
      organicArrows={false}
      autoplay={true}
      interval={5000}
    >
      {sliders.map((slider, index) => (
        <section
          className="home_heros"
          key={index}
          style={{ background: slider.background }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="content aos-init aos-animate">
                  <h1
                    className={` hero-h1 ${
                      slider.header_color ? "text-white" : ""
                    }`}
                    // data-aos='fade-down'
                    // data-aos-offset='500'
                  >
                    {slider.header}
                    {/* <TypeAnimation
                      sequence={[slider.header]}
                      speed={50}
                      style={{ fontSize: '1em' }}
                      repeat={Infinity}
                    /> */}
                  </h1>
                  <p
                    className={` hero-p ${
                      slider.content_color ? "text-white" : ""
                    }`}
                    // data-aos='fade-up'
                    // data-aos-offset='00'
                  >
                    <TypeAnimation
                      sequence={[slider.header, 1000, slider.content, 2000]}
                      speed={50}
                      style={{ fontSize: "2em" }}
                      repeat={Infinity}
                    />
                  </p>

                  <Link
                    className="btn btn-md btn-success demo-btn"
                    to = "https://api.whatsapp.com/send/?phone=9585055661&text&type=phone_number&app_absent=0"
                    style={{ marginLeft: "auto" }}
                    // data-aos='fade-left'
                    // data-aos-offset='300'
                  >
                    <div
                      className="icons"

                      // data-aos='fade-right'
                      // data-aos-offset='300'
                    >
                      <a
                        href = "https://api.whatsapp.com/send/?phone=9585055661&text&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white alig"
                      >
                        Book Now <i className=" bi bi-indent m-right"></i>
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="slide-img2"
                  style={{
                    marginTop: slider.marginTop,
                    marginBottom: slider.marginBottom,
                  }}
                >
                  <img
                    src={slider.image}
                    className="img-fluid aos-init aos-animate "
                    alt={slider.alt_tag}
                    data-aos="fade-up"
                    data-aos-offset="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </AwesomeSlider>
  );
};

export default Hero;
